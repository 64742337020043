import React from "react";
import banner from "assets/img/banner/banner15.webp";

function Vinai() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinai</h2>
      <p>Công ty Cổ phần Nghiên cứu và Ứng dụng Trí tuệ nhân tạo VinAI, tiền thân là Viện nghiên cứu Trí tuệ nhân tạo VinAI (thuộc Tập đoàn Vingroup), là đơn vị nghiên cứu AI hàng đầu thế giới có trụ sở chính tại thành phố Hà Nội, Việt Nam. VinAI có mạng lưới chuyên gia rộng khắp tại Hoa Kỳ, Úc, Pháp và khu vực Châu Á - Thái Bình Dương.</p>
      <p>Sứ mệnh của VinAI là tiến hành nghiên cứu chuyên sâu về AI, xây dựng và phát triển các ứng dụng AI tại Việt Nam và thị trường toàn cầu.</p>
    </div>
  </>;
}

export default Vinai;
