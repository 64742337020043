import React from "react";
import banner from "assets/img/banner/banner06.webp";
import operation13 from "assets/img/operation/operation13.webp";
import operation14 from "assets/img/operation/operation14.webp";
import operation15 from "assets/img/operation/operation15.webp";
import operation16 from "assets/img/operation/operation16.webp";
import operation17 from "assets/img/operation/operation17.webp";
import operation18 from "assets/img/operation/operation18.webp";
import operation19 from "assets/img/operation/operation19.webp";
import operation20 from "assets/img/operation/operation20.webp";
import operation21 from "assets/img/operation/operation21.webp";
import { Link } from "react-router-dom";

const operations = [
  {
    id: 1,
    imgSrc: operation14,
    name: 'VINSCHOOL',
    url: 'vinschool.edu.vn',
    desc: 'Vinschool là hệ thống giáo dục không vì lợi nhuận, liên cấp từ bậc mầm non đến Trung học phổ thông do Tập đoàn Vingroup đầu tư phát triển, hướng đến một ngôi trường Việt Nam mang đẳng cấp quốc tế.',
    link: '/operation/vinschool'
  },
  {
    id: 2,
    imgSrc: operation15,
    name: 'VINMEC',
    url: 'vinmec.com',
    desc: 'Vinmec là hệ thống y tế không vì lợi nhuận do Tập đoàn Vingroup đầu tư phát triển, với tầm nhìn trở thành một hệ thống y tế hàn lâm vươn tầm quốc tế thông qua những nghiên cứu đột phá, nhằm mang lại chất lượng điều trị xuất sắc và dịch vụ chăm sóc hoàn hảo.',
    link: '/operation/vinmec'
  },
  {
    id: 3,
    imgSrc: operation16,
    name: 'VINUNI',
    url: 'vinuni.edu.vn',
    desc: 'Trường Đại học VinUniversity (VinUni) là trường đại học tinh hoa, tư thục, không vì lợi nhuận do Tập đoàn Vingroup sáng lập với khát vọng đào tạo nhân tài cho tương lai và đóng góp cho đất nước một đại học xuất sắc mang đẳng cấp thế giới.',
    link: '/operation/vinuni'
  },
  {
    id: 4,
    imgSrc: operation17,
    name: 'VINFUTURE',
    url: 'vinfutureprize.org',
    desc: 'Quỹ VinFuture là một quỹ độc lập, không vì lợi nhuận tại Việt Nam.',
    link: '/operation/vinfuture'
  },
  {
    id: 5,
    imgSrc: operation18,
    name: 'QUỸ THIỆN TÂM',
    url: 'quythientam.com',
    desc: 'Quỹ Thiện Tâm là một tổ chức phi lợi nhuận thuộc Tập đoàn Vingroup, hoạt động vì mục đích nhân đạo, từ thiện, nhằm “chuyển tải một cách nhanh chóng và hiệu quả nhất tấm lòng của người Vingroup đến với cộng đồng”.',
    link: '/operation/quythientam'
  },
  {
    id: 6,
    imgSrc: operation19,
    name: 'VINIF',
    url: 'vinif.org',
    desc: 'Quỹ Đổi mới sáng tạo Vingroup (VINIF) thuộc Viện Nghiên cứu Dữ liệu lớn (VNCDLL)',
    link: '/operation/vinif'
  },
  {
    id: 7,
    imgSrc: operation20,
    name: 'VINBIOCARE',
    desc: 'VinBioCare thành lập vào tháng 6 năm 2021 với sứ mệnh “Vì một tương lai an toàn cho cộng đồng”.',
    link: '/operation/vinbiocare'
  },
  {
    id: 8,
    imgSrc: operation21,
    name: 'VINBUS',
    url: 'vinbus.vn',
    desc: 'Công ty TNHH Dịch vụ vận tải VinBus được thành lập năm 2019, hoạt động trong lĩnh vực vận tải hành khách công cộng theo mô hình phi lợi nhuận.',
    link: '/operation/vinbus'
  }
];

function Volunteer() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="operation-page">
        <h2 className="title">Thông tin chi tiết</h2>
        <div className="operation-card">
          <div className="operation-card__thumb">
            <img src={operation13} alt="" className="operation-card__image" />
          </div>
          <div className="operation-card__info">
            <strong className="operation-card__title">THIỆN NGUYỆN XÃ HỘI</strong>
            <p className="operation-card__desc">Vingroup đặt cho mình sứ mệnh &quot;Vì một cuộc sống tốt đẹp hơn cho mọi người&quot;, với 3 nhóm hoạt động trọng tâm là Công nghệ - Công nghiệp, Thương mại Dịch vụ, Thiện nguyện Xã hội.</p>
          </div>
        </div>
        <div className="operation">
          <strong className="operation__title">CÁC THƯƠNG HIỆU</strong>
          <ul className="operation__list">
            {operations && operations.map((item) =>
              <li className="operation__item" key={item.id}>
                <div className="operation__thumb">
                  <img src={item.imgSrc} alt="" className="operation__image" />
                </div>
                <div className="operation__info">
                  <strong className="operation__name">{item.name}</strong>
                  <Link to={`https://${item.url}`} target="_blank" className="operation__url">{item.url}</Link>
                  <p className="operation__desc">{item.desc}</p>
                  <Link to={item.link} className="operation__link">Xem chi tiết &#8594;</Link>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Volunteer;
