import React from "react";
import banner from "assets/img/banner/banner21.webp";

function Vinif() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinif</h2>
      <p>Quỹ Đổi mới sáng tạo Vingroup (VINIF) thuộc Viện Nghiên cứu Dữ liệu lớn (VNCDLL) có mục tiêu hỗ trợ các nhà khoa học và các tài năng trẻ thuộc các Trường đại học và các Viện nghiên cứu thực hiện nghiên cứu khoa học, công nghệ và đổi mới sáng tạo trong các lĩnh vực: khoa học, công nghệ, kỹ thuật, y dược, kinh tế và giáo dục nhằm tạo ra những thay đổi tích cực và bền vững cho Việt Nam. Quỹ VinIF sẽ tài trợ cho các dự án và hoạt động nghiên cứu khoa học và đào tạo với định hướng đưa ra các sản phẩm, các giải pháp công nghệ mang lại lợi ích thiết thực cho cộng đồng.</p>
    </div>
  </>;
}

export default Vinif;
