import React from "react";
import banner from "assets/img/banner/banner05.webp";
import operation01 from "assets/img/operation/operation01.webp";
import operation02 from "assets/img/operation/operation02.webp";
import operation03 from "assets/img/operation/operation03.webp";
import operation04 from "assets/img/operation/operation04.webp";
import { Link } from "react-router-dom";

const operations = [
  {
    id: 1,
    imgSrc: operation02,
    name: 'VINHOMES',
    url: 'vinhomes.vn',
    desc: 'Hệ thống căn hộ, biệt thự và nhà phố thương mại với dịch vụ đẳng cấp.',
    link: '/operation/vinfast'
  },
  {
    id: 2,
    imgSrc: operation03,
    name: 'VINPEARL',
    url: 'vinpearl.com',
    desc: 'Tự hào là thương hiệu dẫn đầu, đại diện cho ngành khách sạn du lịch nghỉ dưỡng tại Việt Nam, Vinpearl mang trong mình sứ mệnh nâng tầm trải nghiệm nghỉ dưỡng, mang đến những kỳ nghỉ 5 sao cho du khách Việt Nam và du khách quốc tế.',
    link: '/operation/vinpearl'
  },
  {
    id: 3,
    imgSrc: operation04,
    name: 'VINCOM',
    url: 'vincom.com.vn',
    desc: 'Vincom là thương hiệu bất động sản bán lẻ hàng đầu Việt Nam với 4 dòng sản phẩm là Vincom Center, Vincom Mega Mall, Vincom Plaza và Vincom+.',
    link: '/operation/vincom'
  }
];

function Commerce() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="operation-page">
        <h2 className="title">Thông tin chi tiết</h2>
        <p className="desc">Với mảng Thương mại Dịch vụ - Vingroup tiếp tục đẩy mạnh, hoàn thiện và nâng cấp chất lượng cũng như hiệu quả hoạt động. Thương mại dịch vụ là hệ sinh thái quan trọng để hỗ trợ công tác nghiên cứu và thương mại hóa các sản phẩm công nghệ - công nghiệp.</p>
        <div className="operation-card">
          <div className="operation-card__thumb">
            <img src={operation01} alt="" className="operation-card__image" />
          </div>
          <div className="operation-card__info">
            <strong className="operation-card__title">THƯƠNG MẠI DỊCH VỤ</strong>
            <p className="operation-card__desc">Vingroup đặt cho mình sứ mệnh &quot;Vì một cuộc sống tốt đẹp hơn cho mọi người&quot;, với 3 nhóm hoạt động trọng tâm là Công nghệ - Công nghiệp, Thương mại Dịch vụ, Thiện nguyện Xã hội.</p>
          </div>
        </div>
        <div className="operation">
          <strong className="operation__title">CÁC THƯƠNG HIỆU</strong>
          <ul className="operation__list">
            {operations && operations.map((item) =>
              <li className="operation__item" key={item.id}>
                <div className="operation__thumb">
                  <img src={item.imgSrc} alt="" className="operation__image" />
                </div>
                <div className="operation__info">
                  <strong className="operation__name">{item.name}</strong>
                  <Link to={`https://${item.url}`} target="_blank" className="operation__url">{item.url}</Link>
                  <p className="operation__desc">{item.desc}</p>
                  <Link to={item.link} className="operation__link">Xem chi tiết &#8594;</Link>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Commerce;
