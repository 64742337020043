import React from "react";

function Startegy() {
  return <div className="post-page">
    <h2 className="title title--italic title--red">CHIẾN LƯỢC CON NGƯỜI</h2>
    <p>Vingroup luôn coi nguồn nhân lực là yếu tố cốt lõi và là tài sản quý giá. Với khẩu hiệu: “Vingroup - Mãi mãi tinh thần khởi nghiệp”, Tập đoàn đã xây dựng một đội ngũ nhân sự tinh gọn, có đủ cả Đức và Tài. Mục tiêu tuyển dụng của Tập đoàn là thu hút và chào đón tất cả những ứng viên mong muốn làm việc trong môi trường năng động, tốc độ, sáng tạo và hiệu quả - nơi mỗi cá nhân có thể phát huy tối đa khả năng và kiến thức chuyên môn.</p>
    <p>Tập đoàn luôn tạo một môi trường làm việc chuyên nghiệp, hiện đại, phát huy tối đa quyền được làm việc, cống hiến, phát triển, tôn vinh của người lao động và sự kết hợp hài hoà giữa lợi ích của doanh nghiệp với lợi ích của cán bộ, người lao động.</p>
    <p>Vingroup đặc biệt chú trọng đến công tác phát triển nguồn nhân lực thông qua việc triển khai hiệu quả chính sách đào tạo, nâng cao hiểu biết, trình độ nghiệp vụ cho CBNV. Đào tạo không chỉ với mục đích nâng cao trình độ cho CBNV, để mỗi thành viên đều trở thành một đại diện xứng đáng của Vingroup trong bất cứ hoàn cảnh nào mà thông qua hệ thống đào tạo, Vingroup sẽ góp phần vào việc nâng cao chất lượng nhân sự của các doanh nghiệp Việt Nam nói chung.</p>
  </div>;
}

export default Startegy;
