import React from "react";
import banner from "assets/img/banner/banner16.webp";

function Vinai() {
  return <div className="banner">
    <img src={banner} alt="banner" className="banner__image" />
  </div>;
}

export default Vinai;
