import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-left">
        <strong className="footer__title">Tập đoàn Vingroup (Vingroup JSC)</strong>
        <p className="copyright">&copy; Bản quyền Vingroup 2019</p>
        <Link to="https://vinpearl1.bbbs88.com/service" className="link-service">Hỗ trợ tư vấn</Link>
        <Link to="https://vinpearl1.bbbs88.com/service" className="link-service">Kết nối với chúng tôi:</Link>
      </div>
      <ul className="footer-nav">
        <li className="footer-nav__item">
          <Link to="/" className="footer-nav__link">Trang chủ</Link>
        </li>
        <li className="footer-nav__item">
          <Link to="/introduce" className="footer-nav__link">Giới thiệu Tập đoàn</Link>
        </li>
        <li className="footer-nav__item">
          <Link to="/operation" className="footer-nav__link">Lĩnh vực hoạt động</Link>
        </li>
        <li className="footer-nav__item">
          <Link to="/relationship" className="footer-nav__link">Quan hệ cổ đông</Link>
        </li>
        <li className="footer-nav__item">
          <Link to="/development" className="footer-nav__link">Phát triển bền vững</Link>
        </li>
      </ul>
      <ul className="footer-right">
        <li className="footer-right__item">
          <Link to="/news" className="footer-right__link">Tin tức sự kiện</Link>
        </li>
        <li className="footer-right__item">
          <Link to="/recruitment" className="footer-right__link">Tuyển dụng</Link>
        </li>
        <li className="footer-right__item">
          <Link to="/contact" className="footer-right__link">Liên hệ</Link>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
