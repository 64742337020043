import React from "react";
import { Link } from "react-router-dom";
import banner from "assets/img/banner/banner07.webp";

const list = [
  {
    id: 1,
    title: 'Văn hóa doanh nghiệp',
    desc: 'Vingroup là nơi tập trung những con người ưu tú của Dân tộc Việt Nam và các bạn đồng nghiệp Quốc tế - những người có tư tưởng và hành động kỷ luật, có tài năng và bản lĩnh, có lòng yêu nước và tự tôn dân tộc, hướng thiện và có tinh thần làm việc quyết liệt, triệt để vì những mục đích tốt đẹp.',
    link: '/development/culture'
  },
  {
    id: 2,
    title: 'Chiến lược con người',
    desc: 'Vingroup luôn coi nguồn nhân lực là yếu tố cốt lõi và là tài sản quý giá. Với khẩu hiệu: “Vingroup - Mãi mãi tinh thần khởi nghiệp”, Tập đoàn đã xây dựng một đội ngũ nhân sự tinh gọn, có đủ cả Đức và Tài.',
    link: '/development/strategy'
  },
  {
    id: 3,
    title: 'Môi trường và cộng đồng',
    desc: 'Với mục tiêu phát triển bền vững, Vingroup hiểu rõ tầm quan trọng của việc bảo vệ môi trường trong quá trình thiết kế, xây dựng và khai thác các tổ hợp du lịch, TTTM, khu đô thị, văn phòng và căn hộ.',
    link: '/development/community'
  }
];

function Development() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="development-page">
        <h2 className="title">Khát vọng <em>tiên phong</em></h2>
        <p className="desc">Vingroup đặt cho mình sứ mệnh &quot;Vì một cuộc sống tốt đẹp hơn cho mọi người&quot;, với 3 nhóm hoạt động trọng tâm là Công nghệ - Công nghiệp, Thương mại Dịch vụ, Thiện nguyện Xã hội.</p>
        <ul className="development">
          {list && list.map((item) => 
            <li className="development__item" key={item.id}>
              <strong className="development__title">{item.title}</strong>
              <p className="development__desc">{item.desc}</p>
              <Link to={item.link} className="section__link">Xem chi tiết &#8594;</Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Development;
