import React from "react";
import banner from "assets/img/banner/banner12.webp";

function Vinpearl() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinpearl</h2>
      <p>Tự hào là thương hiệu dẫn đầu, đại diện cho ngành khách sạn du lịch nghỉ dưỡng tại Việt Nam, Vinpearl mang trong mình sứ mệnh nâng tầm trải nghiệm nghỉ dưỡng, mang đến những kỳ nghỉ 5 sao cho du khách Việt Nam và du khách quốc tế. Các cơ sở khách sạn, biệt thự nghỉ dưỡng, các khu vui chơi giải trí của Vinpearl trải dài trên mảnh đất hơn 3000 km đường bờ biển. Mỗi lựa chọn điểm đến tại Vinpearl sẽ là một điểm dừng chân lý tưởng để trải nghiệm nghỉ dưỡng chuẩn quốc tế, cảm nhận trọn vẹn vẻ đẹp của từng thắng cảnh địa phương.</p>
      <p>Bên cạnh cơ sở nghỉ dưỡng, Vinpearl còn sở hữu hệ thống sân Vinpearl Golf, công viên chủ đề đẳng cấp thế giới VinWonders, công viên chăm sóc và bảo tồn động vật bán hoang dã Vinpearl Safari, các khu chăm sóc sức khỏe và sắc đẹp, hệ thống phòng họp sang trọng, cùng các nhà hàng ẩm thực chất lượng quốc tế,… hứa hẹn mang đến cho du khách kỳ nghỉ ngập tràn cảm hứng, trọn vẹn niềm vui.</p>
    </div>
  </>;
}

export default Vinpearl;
