import React from "react";
import banner from "assets/img/banner/banner04.webp";
import operation05 from "assets/img/operation/operation05.webp";
import operation06 from "assets/img/operation/operation06.webp";
import operation07 from "assets/img/operation/operation07.webp";
import operation08 from "assets/img/operation/operation08.webp";
import operation09 from "assets/img/operation/operation09.webp";
import operation10 from "assets/img/operation/operation10.webp";
import operation11 from "assets/img/operation/operation11.webp";
import operation12 from "assets/img/operation/operation12.webp";
import { Link } from "react-router-dom";

const operations = [
  {
    id: 1,
    imgSrc: operation06,
    name: 'VINFAST',
    url: 'vinfastauto.com',
    desc: 'Là thương hiệu ô tô Việt Nam, VinFast hướng tầm nhìn trở thành hãng xe điện thông minh toàn cầu.',
    link: '/operation/vinfast'
  },
  {
    id: 2,
    imgSrc: operation07,
    name: 'VINSMART',
    desc: 'Công ty Nghiên cứu và Sản xuất VinSmart là đơn vị tiên phong trong lĩnh vực công nghệ với sứ mệnh trở thành công ty công nghệ toàn cầu, kiến tạo những sản phẩm điện tử và công nghệ thông minh, chất lượng, ứng dụng trí tuệ nhân tạo AI và kết nối với các thiết bị trên nền tảng IoT.',
    link: '/operation/vinsmart'
  },
  {
    id: 3,
    imgSrc: operation08,
    name: 'VINAI',
    url: 'www.vinai.io',
    desc: 'Công ty Cổ phần Nghiên cứu và Ứng dụng Trí tuệ nhân tạo VinAI, tiền thân là Viện nghiên cứu Trí tuệ nhân tạo VinAI.',
    link: '/operation/vinai'
  },
  {
    id: 4,
    imgSrc: operation09,
    name: 'VINBIGDATA',
    url: 'vinbigdata.org',
    desc: 'Công ty Cổ phần VinBigData được thành lập trên nền tảng thành quả nghiên cứu khoa học của Viện Nghiên cứu Dữ Liệu lớn (thuộc Tập đoàn Vingroup) trong lĩnh vực Khoa Học Dữ Liệu và Trí Tuệ Nhân Tạo, đặc biệt về xử lý hình ảnh và ngôn ngữ.',
    link: '/operation/vinbigdata'
  },
  {
    id: 5,
    imgSrc: operation10,
    name: 'VINCSS',
    url: 'vincss.net',
    desc: 'Công ty TNHH Dịch vụ An ninh mạng VinCSS hoạt động chính trong lĩnh vực nghiên cứu – phát triển, sản xuất và cung cấp các sản phẩm, dịch vụ an ninh mạng toàn diện - thông minh - tự động và xác thực mạnh không mật khẩu.',
    link: '/operation/vinfast'
  },
  {
    id: 6,
    imgSrc: operation11,
    name: 'VINBRAIN',
    url: 'vinbrain.net',
    desc: 'VinBrain là công ty công nghệ tiên phong phát triển các sản phẩm ứng dụng trí tuệ nhân tạo (AI) cho Y Tế.',
    link: '/operation/vinfast'
  },
  {
    id: 7,
    imgSrc: operation12,
    name: 'VINHMS',
    url: 'www.vinhms.com',
    desc: 'VinHMS là công ty sản xuất và kinh doanh phần mềm, chuyên cung cấp những sản phẩm công nghệ chất lượng cao nhằm tối ưu hóa hoạt động kinh doanh của doanh nghiệp.',
    link: '/operation/vinfast'
  }
];

function Technology() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="operation-page">
        <h2 className="title">Thông tin chi tiết</h2>
        <div className="operation-card">
          <div className="operation-card__thumb">
            <img src={operation05} alt="" className="operation-card__image" />
          </div>
          <div className="operation-card__info">
            <strong className="operation-card__title">CÔNG NGHỆ - CÔNG NGHIỆP</strong>
            <p className="operation-card__desc">Vingroup đặt cho mình sứ mệnh &quot;Vì một cuộc sống tốt đẹp hơn cho mọi người&quot;, với 3 nhóm hoạt động trọng tâm là Công nghệ - Công nghiệp, Thương mại Dịch vụ, Thiện nguyện Xã hội.</p>
          </div>
        </div>
        <div className="operation">
          <strong className="operation__title">CÁC THƯƠNG HIỆU</strong>
          <ul className="operation__list">
            {operations && operations.map((item) =>
              <li className="operation__item" key={item.id}>
                <div className="operation__thumb">
                  <img src={item.imgSrc} alt="" className="operation__image" />
                </div>
                <div className="operation__info">
                  <strong className="operation__name">{item.name}</strong>
                  <Link to={`https://${item.url}`} target="_blank" className="operation__url">{item.url}</Link>
                  <p className="operation__desc">{item.desc}</p>
                  <Link to={item.link} className="operation__link">Xem chi tiết &#8594;</Link>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Technology;
