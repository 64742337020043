import React from "react";
import banner from "assets/img/banner/banner08.webp";
import relationship1 from "assets/img/relationship/relationship1.webp";
import relationship2 from "assets/img/relationship/relationship2.webp";
import relationship3 from "assets/img/relationship/relationship3.webp";
import relationship4 from "assets/img/relationship/relationship4.webp";
import company1 from "assets/img/relationship/company1.webp";
import company2 from "assets/img/relationship/company2.webp";
import { Link } from "react-router-dom";

function Relationship() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="relationship-page">
        <h2 className="title">Quan hệ cổ đông</h2>
        <ul className="relationship">
          <li className="relationship__item">
            <Link to="/" className="relationship__title">Giới thiệu</Link>
            <span className="relationship__line">______________________________</span>
            <div className="relationship__thumb">
              <img src={relationship1} alt="" className="relationship__image" />
            </div>
            <div className="relationship__info">
              <Link to="/" className="relationship__link">GIỚI THIỆU DOANH NGHIỆP</Link>
            </div>
          </li>
          <li className="relationship__item is-large">
            <Link to="/" className="relationship__title">Báo cáo tài chính & tóm tắt kết quả kinh doanh</Link>
            <span className="relationship__line">______________________________</span>
            <div className="relationship__thumb">
              <img src={relationship2} alt="" className="relationship__image" />
            </div>
            <div className="relationship__info">
              <Link to="/" className="relationship__link">Tóm Tắt Kết quả Kinh doanh QIV.2022</Link><br/>
              <Link to="/" className="relationship__link">Báo Cáo Tài Chính Hợp Nhất QIV.2022</Link>
            </div>
          </li>
          <li className="relationship__item">
            <strong className="relationship__title">Thông tin liên hệ</strong>
            <span className="relationship__line">______________________________</span>
            <div className="relationship__thumb">
              <img src={relationship3} alt="" className="relationship__image" />
            </div>
            <div className="relationship__info">
              <ul>
                <li>📞: +84 24 3974 9999 - Ext 9795</li>
                <li><a href="mailto:ir@vingroup.net" target="_blank" rel="noreferrer">📩: ir@vingroup.net</a></li>
                <li>☎:+84 24 3974 8888</li>
              </ul>
            </div>
          </li>
          <li className="relationship__item">
            <strong className="relationship__title">Báo cáo thường niên</strong>
            <span className="relationship__line">______________________________</span>
            <div className="relationship__thumb">
              <img src={relationship4} alt="" className="relationship__image" />
            </div>
            <div className="relationship__info">
              <Link to="/" className="relationship__link">Báo Cáo Thường Niên 2021 </Link>
            </div>
          </li>
          <li className="relationship__item">
            <strong className="relationship__title">Tài liệu khác</strong>
            <span className="relationship__line">______________________________</span>
            <div className="relationship__info">
              <ul className="document">
                <li className="document__item"><a href="https://vingroup.net/quan-he-co-dong/cong-bo-thong-tin/tai-lieu-thuyet-trinh" target="_blank" rel="noreferrer" className="document__link">Tài Liệu Thuyết Trình</a></li>
                <li className="document__item"><a href="https://vingroup.net/vi/quan-he-co-dong/cong-bo-thong-tin" target="_blank" rel="noreferrer" className="document__link">Công Bố Thông Tin</a></li>
                <li className="document__item"><a href="https://vingroup.net/vi/quan-he-co-dong/ho-so-doanh-nghiep" target="_blank" rel="noreferrer" className="document__link">Hồ Sơ Doanh Nghiệp</a></li>
                <li className="document__item"><a href="https://vingroup.net/vi/phat-trien-ben-vung" target="_blank" rel="noreferrer" className="document__link">Phát Triển Bền Vững</a></li>
              </ul>
            </div>
          </li>
        </ul>
        <div className="company">
          <strong className="company__title">Các công ty con niêm yết</strong>
          <span className="company__line">_______________________________</span>
          <ul className="company__list">
            <li className="company__item"><Link to="https://vinhomes.vn/vi" target="_blank" className="company__link"><img src={company1} alt="" className="company__image" /></Link></li>
            <li className="company__item"><Link to="https://vincom.com.vn/" target="_blank" className="company__link"><img src={company2} alt="" className="company__image" /></Link></li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Relationship;
