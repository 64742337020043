import React from "react";
import { Link } from "react-router-dom";
import news01 from "assets/img/news/news-page01.webp";
import news02 from "assets/img/news/news-page02.webp";
import news03 from "assets/img/news/news-page03.webp";
import news04 from "assets/img/news/news-page04.webp";
import news05 from "assets/img/news/news-page05.webp";
import news06 from "assets/img/news/news-page06.webp";
import news07 from "assets/img/news/news-page07.webp";
import news08 from "assets/img/news/news-page08.webp";
import news09 from "assets/img/news/news-page09.webp";
import news10 from "assets/img/news/news-page10.webp";
import news11 from "assets/img/news/news-page11.webp";

const featuredNews = [
  {
    id: 1,
    imgSrc: news01,
    title: 'VINGROUP PHÁT HÀNG THẺ VIP NHIỀU QUYỀN LỢI VÀ ĐA TIỆN ÍT'
  },
  {
    id: 2,
    imgSrc: news02,
    title: 'ƯU ĐÃI ĐẶC BIỆT DÀNH CHO CHỦ SỞ HỮU THẺ VIP VINGROUP'
  }
];

const recommendedNews = [
  {
    id: 1,
    imgSrc: news03,
    label: 'Tin công nghiệp',
    title: 'VINFAST TRIỂN KHAI HỆ THỐNG “XƯỞNG DỊCH VỤ KHÔNG NGÀY NGHỈ”',
    time: '13-03-2023'
  },
  {
    id: 2,
    imgSrc: news04,
    label: 'Tin công nghiệp',
    title: 'CHỦ TỊCH TẬP ĐOÀN VINGROUP THÀNH LẬP CÔNG TY CHO THUÊ XE VÀ TAXI ĐIỆN VINFAST',
    time: '06-03-2023'
  },
  {
    id: 3,
    imgSrc: news05,
    label: 'Tin y tế',
    title: 'VINMEC CHÍNH THỨC GIA NHẬP HỆ THỐNG LIÊN KẾT TOÀN CẦU CỦA CLEVELAND CLINIC (MỸ)',
    time: '01-02-2023'
  }
];

const allNews = [
  {
    id: 1,
    imgSrc: news06,
    label: 'Tin công nghiệp',
    title: 'VINFAST CANADA THAM DỰ TRIỂN LÃM Ô TÔ QUỐC TẾ MONTREAL 2023',
    time: '13-03-2023'
  },
  {
    id: 2,
    imgSrc: news07,
    label: 'TIN Vingroup',
    title: 'QUỸ VINFUTURE CHÍNH THỨC PHÁT ĐỘNG ĐỀ CỬ MÙA GIẢI 2023',
    time: '09-01-2023'
  },
  {
    id: 3,
    imgSrc: news08,
    label: 'TIN GIÁO DỤC',
    title: 'VINGROUP HỢP TÁC VỚI BRIGHTON COLLEGE MỞ RỘNG HỆ THỐNG TRƯỜNG QUỐC TẾ TẠI VIỆT NAM',
    time: '21-04-2022'
  },
  {
    id: 4,
    imgSrc: news09,
    label: 'TIN GIÁO DỤC',
    title: 'VINUNI ĐƯỢC PHÊ DUYỆT THÀNH LẬP VÀ HOẠT ĐỘNG THEO MÔ HÌNH ĐẠI HỌC TINH HOA',
    time: '27-12-2019'
  },
  {
    id: 5,
    imgSrc: news10,
    label: 'TIN Y TẾ',
    title: 'VINMEC CÓ CHUYÊN GIA ĐẶT MÁY TẠO NHỊP TIM KHÔNG DÂY ĐỘC LẬP ĐẦU TIÊN TẠI VIỆT NAM',
    time: '27-10-2022'
  },
  {
    id: 6,
    imgSrc: news11,
    label: 'TIN GIÁO DỤC',
    title: 'RA MẮT “TRUNG TÂM NGHIÊN CỨU SỨC KHOẺ THÔNG MINH” TẠI ĐẠI HỌC VINUNI',
    time: '14-07-2022'
  }
];

function News() {
  return (
    <div className="news-page">
      <h2 className="title">Tin tức sự kiện</h2>
      <div className="news-wrap">
        <div className="featured-news">
          <ul className="news-list">
            {featuredNews && featuredNews.map((item) => 
              <li className="news-list__item" key={item.id}>
                <div className="news-list__thumb">
                  <img src={item.imgSrc} alt="" className="news-list__image" />
                </div>
                <div className="news-list__info">
                  <strong className="news-list__label">Tin nổi bật</strong>
                  <strong className="news-list__title">{item.title}</strong>
                  <Link to="/" className="news-list__link">xem chi tiết &#8594;</Link>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="recommended-news">
          <ul className="news-list">
            {recommendedNews && recommendedNews.map((item) =>
              <li className="news-list__item" key={item.id}>
                <div className="news-list__thumb">
                  <img src={item.imgSrc} alt="" className="news-list__image" />
                </div>
                <div className="news-list__info">
                  <strong className="news-list__label">{item.label}</strong>
                  <strong className="news-list__title">{item.title}</strong>
                  <span className="news-list__time">{item.time}</span>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div className="all-news">
          <ul className="news-list">
            {allNews && allNews.map((item) =>
              <li className="news-list__item" key={item.id}>
                <div className="news-list__thumb">
                  <img src={item.imgSrc} alt="" className="news-list__image" />
                </div>
                <div className="news-list__info">
                  <strong className="news-list__label">{item.label}</strong>
                  <strong className="news-list__title">{item.title}</strong>
                  <span className="news-list__time">{item.time}</span>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default News;
