import React, { useRef } from "react";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "assets/img/banner/banner02.webp";
import brand01 from "assets/img/brand/brand01.webp";
import brand02 from "assets/img/brand/brand02.webp";
import brand03 from "assets/img/brand/brand03.webp";
import brand04 from "assets/img/brand/brand04.webp";
import brand05 from "assets/img/brand/brand05.webp";
import brand06 from "assets/img/brand/brand06.webp";
import brand07 from "assets/img/brand/brand07.webp";
import brand08 from "assets/img/brand/brand08.webp";
import brand09 from "assets/img/brand/brand09.webp";
import brand10 from "assets/img/brand/brand10.webp";
import brand11 from "assets/img/brand/brand11.webp";
import brand12 from "assets/img/brand/brand12.webp";
import {ReactComponent as IconLeft} from "assets/icons/chevron-left.svg";
import {ReactComponent as IconRight} from "assets/icons/chevron-right.svg";
// eslint-disable-next-line import/imports-first, import/no-unresolved
import "swiper/css";

const brandSlide = [
  {
    id: 1,
    imgSrc: brand01
  },
  {
    id: 2,
    imgSrc: brand02
  },
  {
    id: 3,
    imgSrc: brand03
  },
  {
    id: 4,
    imgSrc: brand04
  },
  {
    id: 5,
    imgSrc: brand05
  },
  {
    id: 6,
    imgSrc: brand06
  },
  {
    id: 7,
    imgSrc: brand07
  },
  {
    id: 8,
    imgSrc: brand08
  },
  {
    id: 9,
    imgSrc: brand09
  },
  {
    id: 10,
    imgSrc: brand10
  },
  {
    id: 11,
    imgSrc: brand11
  },
  {
    id: 12,
    imgSrc: brand12
  }
];

function Introduce() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="introduce-page">
        <section className="section">
          <h2 className="title">Tập đoàn Vingroup - công ty cp</h2>
          <p className="desc">Tiền thân của Vingroup là Tập đoàn Technocom, thành lập năm 1993 tại Ucraina. Đầu những năm 2000, Technocom trở về Việt Nam, tập trung đầu tư vào lĩnh vực du lịch và bất động sản với hai thương hiệu chiến lược ban đầu là Vinpearl và Vincom. Đến tháng 1/2012, công ty CP Vincom và Công ty CP Vinpearl sáp nhập, chính thức hoạt động dưới mô hình Tập đoàn với tên gọi Tập đoàn Vingroup – Công ty CP.<br/>3 nhóm hoạt động trọng tâm của Tập đoàn bao gồm:<br/>- Công nghệ - Công nghiệp<br/>- Thương mại Dịch vụ<br/>- Thiện nguyện Xã hội<br/>Với mong muốn đem đến cho thị trường những sản phẩm - dịch vụ theo tiêu chuẩn quốc tế và những trải nghiệm hoàn toàn mới về phong cách sống hiện đại, ở bất cứ lĩnh vực nào Vingroup cũng chứng tỏ vai trò tiên phong, dẫn dắt sự thay đổi xu hướng tiêu dùng. </p>
          <div className="brand">
            <div className="brand__info">
              <strong className="brand__title">Các thương hiệu</strong>
              <p className="brand__desc">Với mong muốn đem đến cho thị trường những sản phẩm - dịch vụ theo tiêu chuẩn quốc tế và những trải nghiệm hoàn toàn mới về phong cách sống hiện đại, ở bất cứ lĩnh vực nào Vingroup cũng chứng tỏ vai trò tiên phong, dẫn dắt sự thay đổi xu hướng tiêu dùng.</p>
            </div>
            <Swiper
              modules={[Navigation, Autoplay]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current
              }}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              className="brand__slide"
            >
              {brandSlide && brandSlide.map((brand) => 
                <SwiperSlide key={brand.id} className="brand__item"><img src={brand.imgSrc} alt="" className="brand__image" /></SwiperSlide>
              )}
              <button ref={navigationPrevRef} type="button" className="swiper-button swiper-button-prev" aria-label="Trước"><IconLeft className="icon"/></button>
              <button ref={navigationNextRef} type="button" className="swiper-button swiper-button-next" aria-label="Tiếp"><IconRight className="icon"/></button>
            </Swiper>
          </div>
        </section>
      </div>
    </>
  );
}

export default Introduce;
