import React from "react";

function Community() {
  return <div className="post-page">
    <h2 className="title title--italic title--red">MÔI TRƯỜNG VÀ CỘNG ĐỒNG</h2>
    <h3>Vingroup với môi trường</h3>
    <p>Với mục tiêu phát triển bền vững, Vingroup hiểu rõ tầm quan trọng của việc bảo vệ môi trường trong quá trình thiết kế, xây dựng và khai thác các tổ hợp du lịch, TTTM, khu đô thị, văn phòng và căn hộ. Những công trình đầu tiên Vingroup xây dựng như Vinpearl Resort Nha Trang, tòa tháp Vincom Center Bà Triệu đến các khu đô thị như: Royal City hay Times City, Vinhomes Riverside… đều là những khu du lịch xanh, khu đô thị sinh thái, tòa nhà tiết kiệm năng lượng. Những công trình kiến trúc &quot;xanh&quot; nổi bật gắn với từng dấu ấn phát triển của Vingroup.</p>
    <p>Coi nguyên tắc “xanh” là sợi chỉ đỏ xuyên suốt quá trình hoạt động sản xuất kinh doanh cũng như phát triển các dự án, Tập đoàn Vingroup không chỉ luôn nỗ lực hết mình trong việc giữ gìn và bảo vệ môi trường, mà còn chú trọng việc tuyên truyền ý thức này tới khách hàng, cộng đồng để cùng nhau xây dựng và gìn giữ môi trường trong lành, xứng đáng với đẳng cấp thương hiệu 5 sao của Tập đoàn đã đề ra.</p>
    <h3>Vingroup với cộng đồng</h3>
    <p>Tập đoàn xây dựng văn hóa doanh nghiệp trên khát vọng tiên phong với niềm tự hào về giá trị trí tuệ, bản lĩnh và truyền thống nhân văn của người Việt. Văn hóa này không chỉ thể hiện trong chính sách phúc lợi dành cho người lao động, mà còn trong các hoạt động vì sự phát triển chung của cộng đồng xã hội.</p>
  </div>;
}

export default Community;
