import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "assets/img/banner/banner01.webp";
import news1 from "assets/img/news/news1.webp";
import news2 from "assets/img/news/news2.webp";
import news3 from "assets/img/news/news3.webp";
import fields1 from "assets/img/fields/fields1.webp";
import fields2 from "assets/img/fields/fields2.webp";
import fields3 from "assets/img/fields/fields3.webp";
import fields4 from "assets/img/fields/fields4.webp";
import relationship from "assets/img/img_relationship.webp";
import { ReactComponent as IconLeft } from "assets/icons/chevron-left.svg";
import { ReactComponent as IconRight } from "assets/icons/chevron-right.svg";
// eslint-disable-next-line import/imports-first, import/no-unresolved
import "swiper/css";

const newsList = [
  {
    id: 1,
    title:
      "Công ty GSM đầu tư vào Be group, hỗ trợ tài xế chuyển đổi sang xe điện",
    imgSrc: news1,
    time: "21-03-2023",
  },
  {
    id: 2,
    title: "Vinhomes Ocean Park 2 khởi động chương trình tổ ấm an vui",
    imgSrc: news2,
    time: "17-03-2023",
  },
  {
    id: 3,
    title: 'Vinfast triển khai hệ thống "xưởng dịch vụ không ngày nghỉ"',
    imgSrc: news3,
    time: "13-03-2023",
  },
];

const fieldSlide = [
  {
    id: 1,
    imgSrc: fields1,
  },
  {
    id: 2,
    imgSrc: fields2,
  },
  {
    id: 3,
    imgSrc: fields3,
  },
  {
    id: 4,
    imgSrc: fields4,
  },
];

function Home() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [isShowReward, setShowReward] = useState(false);

  const toggleReward = () => {
    setShowReward(!isShowReward);
  };

  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="home-page">
        <section className="section">
          <h2 className="section__title">Tin tức sự kiện</h2>
          <Link to="/news" className="section__link">
            Xem tất cả &#8594;
          </Link>
          <ul className="news">
            {newsList &&
              newsList.map((news) => (
                <li className="news__item" key={news.id}>
                  <div className="news__thumb">
                    <img src={news.imgSrc} alt="" className="news__image" />
                  </div>
                  <div className="news__info">
                    <strong className="news__title">{news.title}</strong>
                    <span className="news__time">{news.time}</span>
                  </div>
                </li>
              ))}
          </ul>
        </section>
        <section className="section">
          <div className="reward">
            <button
              type="button"
              className="reward__title"
              onClick={toggleReward}
            >
              Chi tiết phần thưởng tham gia --&gt;
            </button>
            {isShowReward && (
              <div className="reward__content">
                <p>
                  💎 Sử dụng thẻ thành viên VIP: Nhận siêu giải thưởng ưu đãi
                  giảm giá khi mua bất động sản Vinhomes, hoặc các dòng xe của
                  VinFast...
                </p>
                <p>
                  💎 Thời gian sử dụng: Từ 1 đến 5 năm miễn phí tùy theo cấp độ
                  của gói tham gia đầu tư của khách hàng.
                </p>
                <p>
                  - Quà tặng dành cho thành viên may mắn nhận thẻ thành viên
                  nhanh nhất ưu đãi giảm giá từ 5% đến 15%.
                </p>
                <p>
                  - Khách hàng đăng ký thành công tham gia quỹ huy động vốn nhận
                  thẻ cổ phần phổ thông giảm giá các sản phẩm của tập đoàn
                  VINGROUP.
                </p>
                <p>
                  - Điền thông tin được in trên thẻ thành viên miễn phí không
                  cần cọc.
                </p>
                <p>
                  - Cách nhận thẻ thành viên, khách hàng đăng ký tài khoản bằng
                  cách nhận mã tham dự của tập đoàn VINGROUP đã cấp phát sau đó
                  mở tài khoản online.
                </p>
                <p>
                  - Tài khoản tham gia đầu tư với giá trị gói đầu tư phù hợp với
                  quy định của tập đoàn mới có thể nhận được thẻ cổ phần phổ
                  thông.
                </p>
                <p>
                  - Khách hàng tạo tài khoản không thực hiện giao dịch kích hoạt
                  tài khoản thì sau 24 tiếng tài khoản sẽ tự động vô hiệu hóa.
                </p>
                <p>
                  - Lưu ý: Hệ thống của Tập đoàn chỉ áp dụng cho thành viên có
                  mã mời tham dự đầu số là VIC.
                </p>
                <a
                  href="https://vinpearla.bbbs88.com/"
                  target="_blank"
                  className="btn-download"
                  rel="noreferrer"
                >
                  Download &#8595;
                </a>
              </div>
            )}
            <a
              href="https://cms.demo24.webtienich.com"
              target="_blank"
              className="btn-login"
              rel="noreferrer"
            >
              Đăng nhập
            </a>
          </div>
        </section>
        <section className="section">
          <div className="fields">
            <div className="fields__info">
              <strong className="fields__title">
                Lĩnh vực <em>Tiên phong</em>
              </strong>
              <p className="fields__desc">
                Với mong muốn đem đến cho thị trường những sản phẩm - dịch vụ
                theo tiêu chuẩn quốc tế và những trải nghiệm hoàn toàn mới về
                phong cách sống hiện đại, ở bất cứ lĩnh vực nào Vingroup cũng
                chứng tỏ vai trò tiên phong, dẫn dắt sự thay đổi xu hướng tiêu
                dùng.
              </p>
            </div>
            <Swiper
              modules={[Navigation, Autoplay]}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              loop
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;
              }}
              className="fields__slide"
            >
              {fieldSlide &&
                fieldSlide.map((fields) => (
                  <SwiperSlide key={fields.id} className="fields__item">
                    <img src={fields.imgSrc} alt="" className="fields__image" />
                  </SwiperSlide>
                ))}
              <button
                ref={navigationPrevRef}
                type="button"
                className="swiper-button swiper-button-prev"
                aria-label="Trước"
              >
                <IconLeft className="icon" />
              </button>
              <button
                ref={navigationNextRef}
                type="button"
                className="swiper-button swiper-button-next"
                aria-label="Tiếp"
              >
                <IconRight className="icon" />
              </button>
            </Swiper>
          </div>
        </section>
        <section className="section relationship">
          <h2 className="section__title section__title--italic">
            Quan hệ cổ đông
          </h2>
          <div className="introduce">
            <strong className="introduce__title">Giới thiệu tập đoàn</strong>
            <div className="introduce__thumb">
              <img src={relationship} alt="" className="introduce__image" />
            </div>
            <p className="introduce__desc">
              Tiền thân của Vingroup là Tập đoàn Technocom, thành lập năm 1993
              tại Ucraina. Đầu những năm 2000, Technocom trở về Việt Nam, tập
              trung đầu tư vào lĩnh vực du lịch và bất động sản với hai thương
              hiệu chiến lược ban đầu là Vinpearl và Vincom. Đến tháng 1/2012,
              công ty CP Vincom và Công ty CP Vinpearl sáp nhập, chính thức hoạt
              động dưới mô hình Tập đoàn với tên gọi Tập đoàn Vingroup – Công ty
              CP.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}

export default Home;
