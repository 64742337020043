import React from "react";
import banner from "assets/img/banner/banner17.webp";

function Vinschool() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinschool</h2>
      <p>Vinschool là hệ thống giáo dục không vì lợi nhuận, liên cấp từ bậc mầm non đến Trung học phổ thông do Tập đoàn Vingroup đầu tư phát triển, hướng đến một ngôi trường Việt Nam mang đẳng cấp quốc tế. Ra đời từ năm 2013, Vinschool được đầu tư bài bản về cơ sở hạ tầng, chất lượng giáo viên và chương trình học, trở thành hệ thống giáo dục tư thục lớn nhất Việt Nam.</p>
      <p>Vinschool mang sứ mệnh ươm mầm tinh hoa, đào tạo học sinh trở thành những công dân có trách nhiệm, có hoài bão với đầy đủ phẩm chất và năng lực để sống hạnh phúc, thành công, góp phần tích cực xây dựng đất nước và hội nhập quốc tế.</p>
    </div>
  </>;
}

export default Vinschool;
