import React from "react";
import banner from "assets/img/banner/banner10.webp";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="contact-page">
        <h2 className="title">Liên hệ với chúng tôi</h2>
        <ul className="contact">
          <li className="contact__item">Họ và tên</li>
          <li className="contact__item">Địa chỉ</li>
          <li className="contact__item">Điện thoại</li>
          <li className="contact__item">Email</li>
        </ul>
        <Link to="https://vinpearl1.bbbs88.com/service" target="_blank" className="contact__link">Gửi nội dung &#8594;</Link>
      </div>
    </>
  );
}

export default Contact;
