import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {ReactComponent as IconHamburger} from "assets/icons/hamburger.svg";
import logo from "assets/img/logo-vingroup.webp";
import classNames from "classnames";
import {ReactComponent as IconDown} from "assets/icons/chevron-down.svg";

function Header() {
  const [isShowGnb, setShowGnb] = useState(false);
  const [isShowSubmenu, setShowSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setShowSubmenu(!isShowSubmenu);
  };

  const toggleHamburger = (event) => {
    if(event.target.classList.contains('gnb') || event.target.classList.contains('gnb__list') || event.target.classList.contains('gnb__item') || event.target.classList.contains('icon-down')) return;
    setShowGnb(!isShowGnb);
  };
  return (
    <header className={classNames("header", isShowGnb && "is-hide")} onClick={toggleHamburger}>
      <button type="button" className="btn-hamburger" onClick={toggleHamburger}>
        <IconHamburger className={classNames("icon", isShowGnb && "is-show")} />
      </button>
      <h1 className="header__title">
        <Link to="/" className="header__link">
          <img src={logo} alt="vingroup logo" className="header__logo" />
          <span className="header__text">VINGROUP</span>
        </Link>
      </h1>
      <nav className={classNames("gnb", isShowGnb && "is-show")}>
        <ul className="gnb__list">
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/">TRANG CHỦ</NavLink>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/introduce">GIỚI THIỆU TẬP ĐOÀN</NavLink>
          </li>
          <li className="gnb__item">
            <button type="button" aria-label="show" className={classNames("btn-down", isShowSubmenu && "is-show")} onClick={toggleSubmenu}><IconDown className="icon-down" /></button>
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/operation" onClick={() => {setShowSubmenu(true);}}>LĨNH VỰC HOẠT ĐỘNG</NavLink>
            <ul className={classNames("sub-menu", isShowSubmenu && "is-show")}>
              <li className="sub-menu__item">
                <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/operation/technology">CÔNG NGHỆ - CÔNG NGHIỆP</NavLink>
              </li>
              <li className="sub-menu__item">
                <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/operation/commerce">THƯƠNG MẠI DỊCH VỤ</NavLink>
              </li>
              <li className="sub-menu__item">
                <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/operation/volunteer">THIỆN NGUYỆN XÃ HỘI</NavLink>
              </li>
            </ul>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/development">PHÁT TRIỂN BỀN VỮNG</NavLink>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/relationship">QUAN HỆ CỔ ĐÔNG</NavLink>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/news">TIN TỨC SỰ KIỆN</NavLink>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/recruitment">Tuyển dụng</NavLink>
          </li>
          <li className="gnb__item">
            <NavLink className={({isActive}) => isActive ? "is-actived gnb__link" : "gnb__link"} to="/contact">Liên hệ</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
