import React from "react";
import banner from "assets/img/banner/banner11.webp";

function Vinfast() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2>Vinfast</h2>
      <p>Là thương hiệu ô tô Việt Nam, VinFast hướng tầm nhìn trở thành hãng xe điện thông minh toàn cầu. VinFast đã đặt nền móng cho ngành công nghiệp sản xuất ô tô - xe máy điện tại Việt Nam, đồng thời đang nỗ lực góp phần thúc đẩy cuộc cách mạng xe điện trên toàn thế giới.</p>
      <p>Với triết lý “Khách hàng là trung tâm”, VinFast luôn không ngừng sáng tạo để tạo ra những sản phẩm đẳng cấp với mức giá tốt nhất và dịch vụ hậu mãi vượt trội, mang lại cơ hội sử dụng xe điện thông minh cho mọi người, góp phần kiến tạo một tương lai xanh, thông minh và bền vững.</p>
      <p><em>Voucher VinFast</em> hay Voucher Vinhome đang là những từ khóa được nhiều người mua xe VinFast quan tâm nhất hiện nay. Quý khách hàng là chủ nhân Voucher “Đẳng cấp tinh hoa” đang muốn hướng dẫn sử dụng Voucher khi mua các dòng sản phẩm ô tô VinFast. Với Voucher “Đẳng cấp tinh hoa” Quý khách hàng có thể dễ dàng sử dụng để sở hữu xe ô tô VinFast với mức giá hấp dẫn.</p>
      <h3>1. Voucher VinFast là gì ?</h3>
      <p>Voucher VinFast hay Voucher “Đẳng cấp tinh hoa” là Voucher được phát hành cho theo từng dự án của Vinhomes, các khách hàng mua nhà Vinhomes được tặng voucher mua xe VinFast. Voucher VinFast có 2 loại là voucher định danh và voucher không định danh. Voucher này được sử dụng để mua bất kỳ các dòng xe VinFast bao gồm Fadil, Lux A 2.0, Lux SA 2.0</p>
      <img src="" alt="" />
      <p>Từ 19/2, Vinhomes triển khai chương trình “Nhà sang, xe xịn – đẳng cấp tinh hoa” – tặng voucher mua xe VinFast có giá trị lên đến 200 triệu đồng cho tất cả khách mua nhà. Theo đó, khách mua bất kỳ sản phẩm Vinhomes sẽ được tặng voucher mua xe VinFast. Khác mua nhà dưới 2 tỷ đồng được tặng voucher trị giá 70 triệu đồng (áp dụng khi mua xe VinFast Fadil); từ 2 đến 3 tỷ đồng được tặng voucher 150 triệu đồng (áp dụng khi mua xe VinFast Lux A2.0); trên 3 tỷ đồng được tặng voucher 200 triệu đồng (áp dụng khi mua xe VinFast Lux SA2.0).</p>
      <p>Bên cạnh đó, tất cả khách hàng sở hữu xe VinFast sẽ được miễn phí gửi xe tại các Trung tâm Thương mại Vincom và Khu đô thị Vinhomes trên toàn quốc. Trong đó, cư dân Vinhomes được miễn phí gửi xe hàng tháng cho tối đa 2 xe máy và 1 ô tô VinFast; các khách hàng khác sẽ được miễn phí gửi xe VinFast tại hệ thống Vincom hoặc Vinhomes trong thời gian tối đa 6 tiếng và không gửi qua đêm. Chương trình áp dụng đến hết tháng 12/2022.</p>
      <p>Đặc biệt, khi mua cùng lúc “nhà Vinhomes, ô tô VinFast” từ 19/2/2020 theo Chương trình này, khách hàng còn được gói hỗ trợ tài chính ưu việt lên tới 35 năm.</p>
      <p>Theo đại diện Vinhomes, chương trình khuyến mãi đầu xuân “Nhà sang, xe xịn, đẳng cấp tinh hoa” mở ra cơ hội dễ dàng sở hữu nhà đẹp – xe tốt cho khách hàng. Đồng thời, thông qua chương trình này, Vinhomes gửi lời tri ân tới những người luôn đồng hành, ủng hộ thương hiệu trên hành trình nâng tầm cuộc sống người Việt.</p>
      <strong>Chú ý: Mọi thắc mắc cần giải đáp liên quan đến việc sử dụng Voucher để mua xe VinFast </strong>
      <strong>Chúng tôi có thiết bị giải mã xác minh Voucher và thực hiện giao dịch bán xe cho khách hàng có Voucher mua xe VinFast theo đúng chính sách của Hãng.</strong>
      <h3>2. Các loại Voucher VinFast hay voucher “Đẳng cấp tinh hoa”</h3>
      <p>Voucher VinFast có 2 loại đó là Voucher định danh và Voucher không định danh</p>
      <h4>2.1. Voucher định danh</h4>
      <p>Voucher định danh là Voucher được Vinhomes phát hành cho các dự án trên toàn quốc. Voucher được thiết kế với 3 tông màu khác nhau và có quy định rõ loại xe VinFast được áp dụng, Số đầu tiên trên Series Voucher lần lượt là số 1, 2, 3. Voucher được ép nhũ các thông tin mặt trước và có tem Hologram 7 màu chống hàng giả của Bộ Công An ở mặt sau. Và được quy định từng loại voucher ứng với với cụ thể từ dòng xe VinFast. Khách hàng không được dùng voucher áp dụng cho dòng xe này để áp dụng cho dòng xe khác</p>
      <img src="" alt="" />
      <p>Loại Voucher mua xe VinFast này được quy định rõ ràng từng loại ứng với các dòng xe cụ thể của VinFast:</p>
      <ul>
        <li>Voucher định danh mệnh giá 70 triệu Đồng: Chỉ được sử dụng để mua xe Fadil</li>
        <li>Voucher định danh mệnh giá 150 triệu Đồng: Chỉ được sử dụng để mua xe Lux A 2.0</li>
        <li>Voucher định danh mệnh giá 200 triệu Đồng: Chỉ được sử dụng để mua xe Lux SA 2.0</li>
      </ul>
      <p>a. Hình ảnh voucher VinFast Fadil – Voucher định danh mua xe Fadil</p>
      <p>Voucher 70.000.000VNĐ (Bảy mươi triệu đồng) áp dụng khi mua xe VinFast Fadil</p>
      <p>VinFast Fadil là mẫu hatchback 5 chỗ, được trang bị động cơ 1.4L, công suất 98 mã lực, mô-men xoắn cực đại 128Nm, hộp số vô cấp đi kèm hệ dẫn động cầu trước. VinFast Fadil phiên bản Tiêu chuẩn có giá 414.900.000 đồng, bản Nâng Cao (Plus) là 449.000.000 đồng và bản Cao Cấp (Full) là 491.900.000 đồng (đã bao gồm VAT).</p>
      <p>b. Hình ảnh voucher VinFast Lux A – Voucher định danh mua xe VinFast Lux A 2.0</p>
      <p>Voucher 150.000.000VNĐ (Một trăm năm mươi triệu đồng) áp dụng khi mua VinFast Lux A2.0</p>
      <p>VinFast Lux A2.0 là mẫu sedan 5 chỗ được trang bị động cơ 2.0L với hai tùy chọn công suất 174 hoặc 228 mã lực, mô-men xoắn cực đại 300 hoặc 350 Nm, đi kèm hộp số ZF tự động 8 cấp cùng hệ dẫn động cầu sau. VinFast Lux A2.0 phiên bản Tiêu Chuẩn (Base) sẽ có giá mới là 1.129.000.000 đồng, bản Nâng Cao (Plus) là 1.217.000.000 đồng, bản Cao Cấp (Premium) là 1.367.700.000 đồng hoặc 1.378.700.000 đồng tùy theo màu da nội thất (đã bao gồm VAT).</p>
      <p>c. Hình ảnh voucher VinFast Lux SA – Voucher định danh mua xe VinFast Lux SA 2.0</p>
      <p>Voucher 200.000.000VNĐ (Hai trăm triệu đồng) áp dụng khi mua VinFast Lux A2.0</p>
      <p>VinFast Lux SA2.0 là mẫu SUV 7 chỗ, được trang bị động cơ I4 2.0L công suất 228 mã lực, mô-men xoắn cực đại 350 Nm, hộp số ZF tự động 8 cấp đi kèm hệ dẫn động cầu sau hoặc dẫn động 4 bánh toàn thời gian. Tiêu Chuẩn (Base) sẽ có giá 1.580.000.000 đồng, bản Nâng Cao (Plus) là 1.664.400.000 đồng, bản Cao Cấp là 1.853.400.000 đồng hoặc 1.864.400.000 đồng tùy nội thất (đã bao gồm VAT). </p>
      <h4>2.2. Voucher không định danh</h4>
      <p>Voucher VinFast không định danh là Voucher được phát hành cho một số dự án nhất định của Vinhomes. Hình thức Voucher Không định danh theo dòng xe có nền màu đỏ, số đầu tiên trên Series Voucher là số 4. Voucher có 3 loại trị giá 100 triệu đồng, 200 triệu đồng và lớn nhất là 350 triệu đồng. Voucher không định danh này được sử dụng để mua bất kỳ các dòng xe VinFast Lux A2.0, VinFast Lux SA2.0 và VinFast Fadil.</p>
      <ul>
        <li>Voucher không định danh mệnh giá 100 triệu Đồng</li>
        <li>Voucher không định danh mệnh giá 200 triệu Đồng</li>
        <li>Voucher không định danh mệnh giá 350 triệu Đồng</li>
      </ul>
      <img src="" alt="" />
      <h3>3. Điều kiện sử dụng Voucher VinFast</h3>
      <ul>
        <li>Đối với Voucher định danh theo từng dòng xe. Khách hàng (“KH”) không sử dụng Voucher áp dụng cho dòng xe này để mua dòng xe khác.</li>
        <li>Voucher chỉ áp dụng cho các hợp đồng mua xe ô tô VinFast được ký với Showroom hoặc Nhà phân phối của VinFast trong thời gian hiệu lực của Voucher.</li>
        <li>Với mỗi xe ô tô, khách hàng chỉ được sử dụng 01 Voucher để thanh toán. Không được gộp nhiều voucher của chương trình này cũng như chương trình khác vào mua 1 xe ô tô</li>
        <li>Mỗi xe ô tô chỉ được sử dụng 01 Voucher để thanh toán. Voucher chỉ được sử dụng 01 lần duy nhất để thanh toán đợt cuối khi nhận xe theo hợp đồng mua bán và không được sử dụng để thanh toán cho bất kỳ khoản đặt cọc nào.</li>
        <li>Khách hàng cần ký hợp đồng mua xe với Showroom, Nhà phân phối của VinFast; Thực hiện đặt cọc mua xe theo chính sách bán hàng của VinFast và đăng ký Voucher còn hạn sử dụng để thanh toán lần cuối khi nhận xe.</li>
        <li>Trường hợp KH vay ngân hàng, Voucher được dùng để thanh toán phần vốn đối ứng 30% giá trị xe.</li>
        <li>Voucher không được quy đổi thành tiền mặt hoặc lợi ích khác trong bất cứ hoàn cảnh nào.</li>
        <li>Voucher chỉ cấp phát 01 lần và không cấp lại.</li>
        <li>Voucher không được áp dụng chung với các chương trình khuyến mại khác, ngoài trừ 02 chương trình sau đây:
          <ul>
            <li>Voucher được phép sử dụng đồng thời với chương trình “Hỗ trợ lãi suất 2 năm” của VinFast cho cả xe Fadil và dòng xe Lux trong thời hạn của CTKM Hỗ trợ lãi suất 2 năm.</li>
            <li>Voucher được phép sử dụng đồng thời với chương trình giảm 10% giá bán lẻ cho khách hàng mua xe Fadil và dòng xe Lux trả thẳng trong thời hạn của CTKM giảm 10% giá bán lẻ.</li>
          </ul>
        </li>
        <li>Trường hợp bị mất, voucher sẽ không được cấp lại. Trường hợp voucher bị hỏng, sẽ được đổi lại theo Quy định của VinFast.</li>
        <li>Phạm vi áp dụng: Voucher được tiếp nhận và thanh toán bởi Showroom VinFast và Nhà phân phối VinFast trên toàn quốc. Thời hạn sử dụng: Hạn sử dụng cố định được in trên Voucher.</li>
      </ul>
      <h3>4. Hướng dẫn sử dụng Voucher mua xe VinFast</h3>
      <h4>4.1. Quy định về Voucher hợp lệ</h4>
      <p>Voucher còn nguyên vẹn, không xước, phai, nhòe, gẫy gập, mất góc, các nội dung thông tin trên cả 2 mặt Voucher hiển thị rõ ràng. Các thông tin chi tiết cần lưu ý:</p>
      <ul>
        <li>Thời điểm thẻ có giá trị sử dụng (Valid Thru).</li>
        <li>Mệnh giá Voucher (Đã gồm VAT).</li>
        <li>Logo thương hiệu của VinFast, Vinhomes.</li>
        <li>Barcode.</li>
      </ul>
      <p>Voucher có dán tem Hologram bảy màu chống hàng giả của Bộ Công An. Tem bảy màu dán trên thẻ không được phép rách mất góc, trầy xước, phai màu.</p>
      <h4>4.2.Thời gian sử dụng của Voucher</h4>
      <ul>
        <li>Thời hạn sử dụng của Voucher được in ở cả 2 mặt:</li>
        <li>Mặt trước: Nội dung nằm ở góc trái dưới cùng – Valid Thru</li>
        <li>Mặt sau: Nội dung thể hiện trên phần “Trích lược điều kiện sử dụng”.</li>
      </ul>
      <h4>4.3. Lưu ý khi trao đổi Voucher</h4>
      <ul>
        <li>KH cần xác minh tính hợp lệ của Voucher trước khi trao đổi, nội dung cần kiểm tra theo “Quy định về Voucher hợp lệ”.</li>
        <li>Các điểm bán hàng của VinFast (Showroom hoặc Nhà phân phối) được trang bị thiết bị đầu đọc thẻ có thể kiểm tra tính thật giả của Voucher. KH nên đến các điểm bán hàng của VinFast để xác nhận Voucher trước khi thực hiện trao đổi.</li>
      </ul>
      <h3>5. Những câu hỏi thường gặp về Voucher mua xe VinFast</h3>
      <p>Câu 1: Voucher “Đẳng Cấp Tinh Hoa” được cấp phát bởi những dự án nhà Vinhomes nào? Điều kiện để KH nhận được Voucher là gì?</p>
      <p>– Voucher “Đẳng Cấp Tinh Hoa” được triển khai trên toàn quốc, cụ thể theo chính sách bán hàng được công bố của từng dự án tại từng thời điểm hiệu lực. Điều kiện để nhận được voucher là khách hàng đã ký Hợp đồng mua bán.</p>
      <p>Câu 2: Voucher “Đẳng Cấp Tinh Hoa” có được áp dụng cho các dự án ShopHouse trên toàn quốc hiện đang do VCR ký hợp đồng & bán không?</p>
      <p>– Voucher “Đẳng Cấp Tinh Hoa” được áp dụng cho các dự án ShopHouse do VCR làm CĐT và các sản phẩm Condotel/biệt thự biển/shop do Vinpearl làm CĐT, cụ thể theo chính sách bán hàng được Chủ đầu tư công bố của từng dự án tại từng thời điểm hiệu lực.</p>
      <p>Câu 3: Nếu KH mua đồng thời cả nhà và xe (phương án vay vốn và không vay vốn) thì ưu đãi chi tiết như nào? Ưu đãi vay lên tới 35 năm chỉ áp dụng cho mua nhà hay cả khi mua xe VinFast cũng được vay 35 năm?</p>
      <p>– Nếu mua nhà, xe theo phương án vay vốn thì được áp dụng chính sách vay gói combo của Ngân hàng, thời hạn vay nhà lên tới 35 năm, xe lên tới 15 năm. Hiện nay Vinhomes & VinFast đang phối hợp với các Ngân hàng để xây dựng chính sách & công bố chính thức trong thời gian tới.</p>
      <p>Câu 4: Kể từ ngày ký hợp đồng mua bán nhà sau bao lâu thì KH được nhận voucher ưu đãi? Nhận như thế nào?</p>
      <p>– KH nhận ngay khi ký HĐMB và nhận tại bộ phận thủ tục của Vinhomes.</p>
      <p>Câu 5: Xử lý voucher đối với KH bị thanh lý HĐMB mua nhà vì bất kỳ lý do nào.</p>
      <p>– KH phải hoàn trả Voucher nếu hủy HĐMB mua nhà Vinhomes, nếu làm mất Voucher KH phải hoàn lại bằng tiền mặt</p>
      <p>Câu 6: Voucher “Đẳng Cấp Tinh Hoa” dùng để đặt cọc mua xe ô tô VinFast được không?</p>
      <p>– Voucher không dùng cho đặt cọc, chỉ được dùng vào đợt thanh toán cuối cùng. Trường hợp vay vốn ngân hàng, có thể sử dụng để thanh toán toàn bộ hoặc một (01) phần tiền đối ứng. khách hàng vẫn phải đặt cọc theo quy định bằng chuyển khoản hoặc tiền mặt.</p>
      <p>Câu 7: Voucher “Đẳng Cấp Tinh Hoa”có được áp dụng chung với các Chương trình khuyến mại khác của VinFast hay không?</p>
      <p>– Khách hàng có quyền sử dụng đồng thời Voucher cùng với chương trình khuyến mại hiện hành như dưới đây cho tới khi có thông báo mới từ VinFast.</p>
      <p>Đối với xe Fadil:</p>
      <ul>
        <li>VinFast chi hộ lãi suất 2 năm đầu. 3 năm tiếp theo cố định lãi suất 10.5%/năm.</li>
        <li>Nếu KH không vay ngân hàng: Giảm thẳng tiền mặt tương đương 10% giá trị xe</li>
        <li>Nếu KH là doanh nghiệp KD vận tải: Giảm thẳng tiền mặt tương đương 13% giá trị xe, có điều kiện đi kèm</li>
      </ul>
      <p>Đối với xe Lux A2.0 và xe Lux SA2.0</p>
      <ul>
        <li>VinFast chi hộ lãi suất 2 năm đầu. 6 năm tiếp theo cố định lãi suất 10.5%/năm.</li>
        <li>Nếu KH không vay ngân hàng: Giảm thẳng tiền mặt tương đương 10% giá trị xe</li>
        <li>Nếu KH là doanh nghiệp KD vận tải: Giảm thẳng tiền mặt tương đương 13% giá trị xe, có điều kiện đi kèm.</li>
      </ul>
      <p>Câu 8: Có được gộp nhiều voucher để mua 1 xe ô tô không?</p>
      <p>– Với mỗi xe ô tô, khách hàng chỉ được sử dụng 01 Voucher để thanh toán. Không được gộp nhiều voucher của chương trình này cũng như chương trình khác vào mua 1 xe ô tô.</p>
      <p>Câu 9: Thời hạn của Voucher được xác định như thế nào?</p>
      <p>– Hạn sử dụng cố định được in trên Voucher. Hóa đơn mua xe phải được xuất trong hạn sử dụng Voucher (Hạn xuất hóa đơn là ngày làm việc cuối cùng của tháng in trên Voucher, nếu ngày làm việc rơi vào ngày nghỉ thì sẽ được áp dụng cho ngày làm việc tiếp theo).</p>
      <p>Câu 10: Trường hợp voucher bị hư hỏng, bị thất lạc thì có được cấp lại hay không?</p>
      <p>– Trường hợp bị mất, voucher sẽ không được cấp lại. Trường hợp voucher bị hỏng, sẽ được đổi lại. Hướng dẫn sử dụng voucher có trên website chính thức của VinFast.</p>
      <p>Câu 11: KH đã đặt cọc mua xe VinFast từ trước thời điểm phát hành voucher, trước thời điểm hiệu lực của dự án Vinhomes thì có được sử dụng Voucher này không?</p>
      <p>– Được áp dụng</p>
      <p>Câu 12: Voucher có được phép quy đổi sang giá trị khác hoặc hoàn tiền mặt không?</p>
      <p>– Voucher không được phép quy đổi sang giá trị khác hoặc hoàn tiền mặt trong bất kỳ trường hợp nào.</p>
      <p>Câu 13: Voucher có được phép chuyển nhượng không?</p>
      <p>– Voucher được phép chuyển nhượng.</p>
      <h3>6. Mua bán Voucher VinFast ở đâu ?</h3>
      <p>Ban lãnh đạo của VinFast và Vinhome hay cụ thể là thông tin từ Vingroup cho phép khách hàng chuyển nhượng lại voucher mua xe VinFast. Nhưng nghiêm cấm các nhân viên trực thuộc các bộ phận đứng ra giao dịch mua bán. Vậy nên bạn đang có nhu cầu mua bán Voucher VinFast thì sẽ không có sales nào có thể bán hoặc mua được cho bạn.</p>
      <p>Bạn đang có Voucher xe Vinfast và đang có nhu cầu chuyển nhượng ? Bạn đang muốn mua Voucher VinFast để tiết kiệm chi phí khi mua xe VinFast ? Hãy liên hệ ngay với chúng tôi để được kết nối với khách hàng có nhu cầu mua bán Voucher VinFast.</p>
      <strong>Lưu ý chúng tôi không bán hoặc mua Voucher mà chỉ hỗ trợ bạn tìm người có nhu cầu phù hợp cũng như tư vấn, kiểm tra chính xác nhất về các thông tin thẻ Voucher mà bạn đang quan tâm.</strong>
      <p>Trên đây là những thông tin hướng dẫn sử dụng và quy định về việc sử dụng Voucher “Đẳng cấp tinh hoa” mua xe ô tô VinFast. Mọi thông tin chi tiết quý khách hàng Vui lòng liên hệ Hotline VinFast Online để được giải đáp. Hãy nhấc máy và liên hệ nếu bạn đang có nhu cầu bán Voucher xe Vinfast hoặc mua Voucher xe Vinfast .</p>
    </div>
  </>;
}

export default Vinfast;
