import React from "react";
import banner from "assets/img/banner/banner20.webp";

function Vinfuture() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinfuture</h2>
      <p>Quỹ VinFuture là một quỹ độc lập, không vì lợi nhuận tại Việt Nam, do ông Phạm Nhật Vượng – Chủ tịch Tập đoàn Vingroup và phu nhân – bà Phạm Thu Hương sáng lập và tài trợ. Sứ mệnh của Quỹ VinFuture là xây dựng một tương lai tươi đẹp, nơi nghiên cứu khoa học và đổi mới công nghệ có mục tiêu phụng sự con người, thúc đẩy các thay đổi tích cực cho cuộc sống và tạo ra một thế giới công bằng và bền vững hơn cho các thế hệ sau.</p>
      <p>Hoạt động cốt lõi của Quỹ là tổ chức Giải thưởng VinFuture - giải thưởng Khoa học và Công nghệ toàn cầu đầu tiên do người Việt Nam khởi xướng và là một trong những giải thường niên có giá trị lớn nhất thế giới.</p>
    </div>
  </>;
}

export default Vinfuture;
