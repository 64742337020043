import React from "react";
import banner from "assets/img/banner/banner13.webp";

function Vincom() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vincom</h2>
      <p>Vincom là thương hiệu bất động sản bán lẻ hàng đầu Việt Nam với 4 dòng sản phẩm là Vincom Center, Vincom Mega Mall, Vincom Plaza và Vincom+.</p>
      <p>Với độ phủ trên toàn quốc, các loại hình mua sắm, ẩm thực và vui chơi giải trí đa dạng, Vincom có vai trò dẫn dắt xu hướng tiêu dùng, khẳng định uy tín của một nhà đầu tư, quản lý bất động sản thương mại chuyên nghiệp.</p>
      <p>Từ ngày khai trương TTTM đầu tiên năm 2004, đến nay, Vincom Retail đang là chủ sở hữu mặt bằng TTTM lớn nhất Việt Nam.</p>
    </div>
  </>;
}

export default Vincom;
