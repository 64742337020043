import React from "react";
import banner from "assets/img/banner/banner22.webp";

function Vinbiocare() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinbiocare</h2>
      <p>VinBioCare thành lập vào tháng 6 năm 2021 với sứ mệnh “Vì một tương lai an toàn cho cộng đồng”.</p>
      <p>Tầm nhìn của VinBioCare là tiến tới xây dựng và tự chủ hệ sinh thái Nghiên cứu – Sản xuất – Đào tạo về Công nghệ Sinh học, dược phẩm công nghệ cao phục vụ cộng đồng</p>
    </div>
  </>;
}

export default Vinbiocare;
