import React from "react";
import banner from "assets/img/banner/banner14.webp";

function Vinsmart() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinsmart</h2>
      <p>Được thành lập vào tháng 6/2018, Công ty Nghiên cứu và Sản xuất VinSmart là đơn vị tiên phong trong lĩnh vực công nghệ với sứ mệnh trở thành công ty công nghệ toàn cầu, kiến tạo những sản phẩm điện tử và công nghệ thông minh, chất lượng, ứng dụng trí tuệ nhân tạo AI và kết nối với các thiết bị trên nền tảng IoT.</p>
      <p>VinSmart tập trung phát triển các tính năng thông tin – giải trí – dịch vụ (Infotainment) và sản xuất linh kiện điện tử… phục vụ cho ngành công nghiệp ô tô điện thông minh; đồng thời đẩy mạnh việc nghiên cứu phát triển ứng dụng nhà thông minh, hệ thống thiết bị thông minh… cho các đô thị và thành phố thông minh.</p>
    </div>
  </>;
}

export default Vinsmart;
