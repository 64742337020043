import React from "react";
import banner from "assets/img/banner/banner19.webp";

function Vinuni() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinuni</h2>
      <p>Trường Đại học VinUniversity (VinUni) là trường đại học tinh hoa, tư thục, không vì lợi nhuận do Tập đoàn Vingroup sáng lập với khát vọng đào tạo nhân tài cho tương lai và đóng góp cho đất nước một đại học xuất sắc mang đẳng cấp thế giới.</p>
      <p>Mục tiêu của VinUni là trở thành một trong 50 trường Đại học trẻ hàng đầu thế giới. Trường đã hợp tác chiến lược toàn diện với 2 trong số Top 20 Đại học tốt nhất toàn cầu là Đại học Cornell và Đại học Pennsylvania, đồng thời có thỏa thuận đào tạo tích hợp song bằng với các đại học hàng đầu về khoa học máy tính, công nghệ, quản trị kinh doanh và y khoa.</p>
      <p>Được xây dựng dựa trên các chuẩn mực cao nhất về nghiên cứu, giảng dạy, việc làm và triển vọng quốc tế, ngay trong năm học đầu tiên, VinUniversity đã được tổ chức xếp hạng đại học Quacquarelli Symonds (QS) trao chứng nhận đạt tiêu chuẩn 5 sao trong 3 lĩnh vực: Cơ sở vật chất, Phát triển học thuật và Phát triển toàn diện.</p>
    </div>
  </>;
}

export default Vinuni;
