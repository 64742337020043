import React from "react";
import banner from "assets/img/banner/banner09.webp";
import recruitment01 from 'assets/img/recruitment/recruitment01.webp';
import recruitment02 from 'assets/img/recruitment/recruitment02.webp';
import recruitment03 from 'assets/img/recruitment/recruitment03.webp';
import recruitment04 from 'assets/img/recruitment/recruitment04.webp';
import recruitment05 from 'assets/img/recruitment/recruitment05.webp';
import recruitment06 from 'assets/img/recruitment/recruitment06.webp';
import recruitment07 from 'assets/img/recruitment/recruitment07.webp';
import recruitment08 from 'assets/img/recruitment/recruitment08.webp';
import recruitment09 from 'assets/img/recruitment/recruitment09.webp';
import recruitment10 from 'assets/img/recruitment/recruitment10.webp';
import recruitment11 from 'assets/img/recruitment/recruitment11.webp';
import recruitment12 from 'assets/img/recruitment/recruitment12.webp';
import recruitment13 from 'assets/img/recruitment/recruitment13.webp';
import recruitment14 from 'assets/img/recruitment/recruitment14.webp';
import recruitment15 from 'assets/img/recruitment/recruitment15.webp';
import recruitment16 from 'assets/img/recruitment/recruitment16.webp';
import recruitment17 from 'assets/img/recruitment/recruitment17.webp';
import recruitment18 from 'assets/img/recruitment/recruitment18.webp';
import recruitment19 from 'assets/img/recruitment/recruitment19.webp';
import recruitment20 from 'assets/img/recruitment/recruitment20.webp';
import recruitment21 from 'assets/img/recruitment/recruitment21.webp';
import recruitment22 from 'assets/img/recruitment/recruitment22.webp';
import recruitment23 from 'assets/img/recruitment/recruitment23.webp';
import recruitment24 from 'assets/img/recruitment/recruitment24.webp';
import recruitment25 from 'assets/img/recruitment/recruitment25.webp';
import recruitment26 from 'assets/img/recruitment/recruitment26.webp';
import recruitment27 from 'assets/img/recruitment/recruitment27.webp';
import { Link } from "react-router-dom";

const brands = [
  {
    id: 1,
    imgSrc: recruitment01
  },
  {
    id: 2,
    imgSrc: recruitment02
  },
  {
    id: 3,
    imgSrc: recruitment03
  },
  {
    id: 4,
    imgSrc: recruitment04
  },
  {
    id: 5,
    imgSrc: recruitment05
  },
  {
    id: 6,
    imgSrc: recruitment06
  },
  {
    id: 7,
    imgSrc: recruitment07
  },
  {
    id: 8,
    imgSrc: recruitment08
  },
  {
    id: 9,
    imgSrc: recruitment09
  },
  {
    id: 10,
    imgSrc: recruitment10
  },
  {
    id: 11,
    imgSrc: recruitment11
  },
  {
    id: 12,
    imgSrc: recruitment12
  },
  {
    id: 13,
    imgSrc: recruitment13
  },
  {
    id: 14,
    imgSrc: recruitment14
  },
  {
    id: 15,
    imgSrc: recruitment15
  },
  {
    id: 16,
    imgSrc: recruitment16
  },
  {
    id: 17,
    imgSrc: recruitment17
  },
  {
    id: 18,
    imgSrc: recruitment18
  },
  {
    id: 19,
    imgSrc: recruitment19
  }
];

const coreValue = [
  {
    id: 1,
    imgSrc: recruitment22,
    title: '———◆◆◆ Tín ◆◆◆———',
    desc: 'Vingroup đặt chữ TÍN lên vị trí hàng đầu, lấy chữ TÍN làm vũ khí cạnh tranh và bảo vệ chữ TÍN như bảo vệ danh dự của chính mình.'
  },
  {
    id: 2,
    imgSrc: recruitment23,
    title: '———◆◆◆ Tâm ◆◆◆———',
    desc: 'Vingroup đặt chữ TÂM là một trong những nền tảng quan trọng của việc kinh doanh. Chúng ta thượng tôn pháp luật và duy trì đạo đức nghề nghiệp, đạo đức xã hội ở tiêu chuẩn cao nhất.'
  },
  {
    id: 3,
    imgSrc: recruitment24,
    title: '———◆◆◆ Trí ◆◆◆———',
    desc: 'Vingroup coi sáng tạo là sức sống, là đòn bẩy phát triển, nhằm tạo ra giá trị khác biệt và bản sắc riêng trong mỗi gói sản phẩm – dịch vụ.'
  },
  {
    id: 4,
    imgSrc: recruitment25,
    title: '———◆◆◆ Tốc ◆◆◆———',
    desc: 'Vingroup lấy “Tốc độ, hiệu quả trong từng hành động” làm tôn chỉ và lấy “Quyết định nhanh – Đầu tư nhanh – Triển khai nhanh – Bán hàng nhanh – Thay đổi và thích ứng nhanh…” làm giá trị bản sắc.'
  },
  {
    id: 5,
    imgSrc: recruitment26,
    title: '———◆◆◆ Tình ◆◆◆———',
    desc: 'Vingroup có mục tiêu là: Tập hợp những con người tinh hoa để làm nên những sản phẩm - dịch vụ tinh hoa; mọi thành viên được thụ hưởng cuộc sống tinh hoa và góp phần xây dựng một xã hội tinh hoa.'
  },
  {
    id: 6,
    imgSrc: recruitment27,
    title: '———◆◆◆ Nhân ◆◆◆———',
    desc: 'Vingroup xây dựng các mối quan hệ với khách hàng, đối tác, đồng nghiệp, nhà đầu tư và xã hội bằng sự thiện chí, tình thân ái, tinh thần nhân văn.'
  }
];

function Recruitment() {
  return (
    <>
      <div className="banner">
        <img src={banner} alt="banner" className="banner__image" />
      </div>
      <div className="recruitment-page">
        <h2 className="title">💎 VIỆC LÀM THEO THƯƠNG HIỆU</h2>
        <ul className="recruitment">
          {brands && brands.map((item) =>
            <li className="recruitment__item" key={item.id}><img src={item.imgSrc} alt="" className="recruitment__image" /></li>
          )}
        </ul>
        <div className="card">
          <div className="card__thumb">
            <img src={recruitment20} alt="" className="card__image" />
          </div>
          <div className="card__info">
            <strong className="card__title">Tầm nhìn & sứ mệnh</strong>
            <p className="card__desc">Vingroup đặt cho mình sứ mệnh &quot;Vì một cuộc sống tốt đẹp hơn cho mọi người&quot;, với 3 trụ cột cốt lõi là Công nghệ - Công nghiệp, Thương mại Dịch vụ, Thiện nguyện Xã hội.</p>
            <Link to="https://tuyendung.vingroup.net/vision" className="card__link">xem chi tiết &#8594;</Link>
          </div>
        </div>
        <div className="card">
          <div className="card__info">
            <strong className="card__title">Đội ngũ nhân sự</strong>
            <p className="card__desc">Trải qua chặng đường dài trưởng thành và phát triển, chính những con người Vingroup đã làm nên những giá trị tốt đẹp, đóng góp vào thành công của Tập đoàn hôm nay.</p>
            <Link to="https://tuyendung.vingroup.net/people-of-vingroup" className="card__link">xem chi tiết &#8594;</Link>
          </div>
          <div className="card__thumb">
            <img src={recruitment21} alt="" className="card__image" />
          </div>
        </div>
        <h2 className="title">💎GIÁ TRỊ CỐT LÕI</h2>
        <ul className="core-value">
          {coreValue && coreValue.map((item) => 
            <li className="core-value__item" key={item.id}>
              <div className="core-value__thumb">
                <img src={item.imgSrc} alt="" className="core-value__image" />
              </div>
              <div className="core-value__info">
                <strong className="core-value__title">{item.title}</strong>
                <p className="core-value__desc">{item.desc}</p>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Recruitment;
