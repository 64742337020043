import React from "react";
import { Routes, Route } from "react-router-dom";
import BasicLayout from "_common/component/Layout/BasicLayout";
import PageNotfound from "_common/component/PageNotfound";
import HomePage from "pages/Home";
import IntroducePage from "pages/Introduce";
import OperationPage from "pages/Operation";
import DevelopmentPage from "pages/Development";
import RelationshipPage from "pages/Relationship";
import NewsPage from "pages/News";
import RecruitmentPage from "pages/Recruitment";
import ContactPage from "pages/Contact";
import CulturePage from "pages/Development/Culture";
import StrategyPage from "pages/Development/Strategy";
import CommunityPage from "pages/Development/Community";
import TechnologyPage from "pages/Operation/Technology";
import CommercePage from "pages/Operation/Commerce";
import VolunteerPage from "pages/Operation/Volunteer";
import VinfastPage from "pages/Operation/Vinfast";
import VinpearlPage from "pages/Operation/Vinpearl";
import VincomPage from "pages/Operation/Vincom";
import VinsmartPage from "pages/Operation/Vinsmart";
import VinaiPage from "pages/Operation/Vinai";
import VinbigdataPage from "pages/Operation/Vinbigdata";
import VinschoolPage from "pages/Operation/Vinschool";
import VinmecPage from "pages/Operation/Vinmec";
import VinuniPage from "pages/Operation/Vinuni";
import VinfuturePage from "pages/Operation/Vinfuture";
import QuyThienTamPage from "pages/Operation/QuyThienTam";
import VinifPage from "pages/Operation/Vinif";
import VinbiocarePage from "pages/Operation/Vinbiocare";
import VinbusPage from "pages/Operation/Vinbus";

export function Routers() {
  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<HomePage />} />
        <Route path="home" element={<HomePage />} />
        <Route path="introduce" element={<IntroducePage />} />
        <Route path="operation" element={<OperationPage />} />
        <Route path="operation/technology" element={<TechnologyPage />} />
        <Route path="operation/commerce" element={<CommercePage />} />
        <Route path="operation/volunteer" element={<VolunteerPage />} />
        <Route path="operation/vinfast" element={<VinfastPage />} />
        <Route path="operation/vinpearl" element={<VinpearlPage />} />
        <Route path="operation/vincom" element={<VincomPage />} />
        <Route path="operation/vinsmart" element={<VinsmartPage />} />
        <Route path="operation/vinai" element={<VinaiPage />} />
        <Route path="operation/vinbigdata" element={<VinbigdataPage />} />
        <Route path="operation/vinschool" element={<VinschoolPage />} />
        <Route path="operation/vinmec" element={<VinmecPage />} />
        <Route path="operation/vinuni" element={<VinuniPage />} />
        <Route path="operation/vinfuture" element={<VinfuturePage />} />
        <Route path="operation/quythientam" element={<QuyThienTamPage />} />
        <Route path="operation/vinif" element={<VinifPage />} />
        <Route path="operation/vinbiocare" element={<VinbiocarePage />} />
        <Route path="operation/vinbus" element={<VinbusPage />} />
        <Route path="development" element={<DevelopmentPage />} />
        <Route path="development/culture" element={<CulturePage />} />
        <Route path="development/strategy" element={<StrategyPage />} />
        <Route path="development/community" element={<CommunityPage />} />
        <Route path="relationship" element={<RelationshipPage />} />
        <Route path="news" element={<NewsPage />} />
        <Route path="recruitment" element={<RecruitmentPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
