import React from "react";
import banner from "assets/img/banner/banner18.webp";

function Vinmec() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinmec</h2>
      <p>Vinmec là hệ thống y tế không vì lợi nhuận do Tập đoàn Vingroup đầu tư phát triển, với tầm nhìn trở thành một hệ thống y tế hàn lâm vươn tầm quốc tế thông qua những nghiên cứu đột phá, nhằm mang lại chất lượng điều trị xuất sắc và dịch vụ chăm sóc hoàn hảo.</p>
      <p>Ra đời năm 2012, Vinmec có đội ngũ Giáo sư, Tiến sĩ, Bác sĩ giàu kinh nghiệm, cơ sở hạ tầng vượt trội, hệ thống thiết bị tối tân, công nghệ khám chữa bệnh hiện đại. Hiện Vinmec sở hữu hệ thống bệnh viện và phòng khám đa khoa tiêu chuẩn quốc tế tại nhiều tỉnh, thành phố lớn trên khắp cả nước. Trong đó, Vinmec Times City và Vinmec Central Park đạt JCI (Joint Commission International) - chứng chỉ an toàn bệnh viện khắt khe nhất thế giới, tạo ra giá trị khác biệt trong chăm sóc sức khỏe tiêu chuẩn quốc tế tại Việt Nam.</p>
    </div>
  </>;
}

export default Vinmec;
