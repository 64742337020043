import React from "react";
import banner from "assets/img/banner/banner23.webp";

function Vinbus() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Vinbus</h2>
      <p>Công ty TNHH Dịch vụ vận tải VinBus được thành lập năm 2019, hoạt động trong lĩnh vực vận tải hành khách công cộng theo mô hình phi lợi nhuận.</p>
      <p>Công ty vận hành hệ thống giao thông công cộng bằng xe buýt điện đầu tiên tại Việt Nam, góp phần xây dựng hệ thống giao thông công cộng văn minh, hiện đại, giảm ô nhiễm không khí và tiếng ồn cho các đô thị lớn của Việt Nam.</p>
    </div>
  </>;
}

export default Vinbus;
