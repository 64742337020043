import React from "react";

function Culture() {
  return <div className="post-page">
    <h2 className="title title--italic title--red">VĂN HÓA DOANH NGHIỆP</h2>
    <p>Vingroup là nơi tập trung những con người ưu tú của Dân tộc Việt Nam và các bạn đồng nghiệp Quốc tế - những người có tư tưởng và hành động kỷ luật, có tài năng và bản lĩnh, có lòng yêu nước và tự tôn dân tộc, hướng thiện và có tinh thần làm việc quyết liệt, triệt để vì những mục đích tốt đẹp.</p>
    <p>Mỗi thành viên của Vingroup luôn chủ động, nỗ lực học hỏi, phấn đấu không ngừng để hoàn thiện bản thân, luôn lấy Văn hóa Tập đoàn và 6 giá trị cốt lõi của Tập đoàn làm kim chỉ nam để điều chỉnh mọi hành vi của mình.</p>
    <p>Chúng tôi không ngừng sáng tạo để hướng tới mục tiêu &quot;Con người tinh hoa - Sản phẩm/dịch vụ tinh hoa - Cuộc sống tinh hoa - Xã hội tinh hoa&quot;. Và mỗi ngày trôi qua, khắp nơi trên đất nước Việt Nam, bất kể ngày đêm, nắng mưa, các công trình mang thương hiệu Vingroup vẫn vươn cao mãi. Tất cả vẫn ngày đêm nỗ lực vì một Vingroup phát triển bền vững, vì một cuộc sống tốt đẹp hơn cho thế hệ tương lai.</p>
    <p>Với tinh thần thượng tôn kỷ luật, văn hóa Vingroup, trước hết chính là văn hóa của sự chuyên nghiệp thể hiện qua 6 giá trị cốt lõi &quot; TÍN - TÂM - TRÍ - TỐC - TINH - NHÂN&quot;. Văn hóa làm việc tốc độ cao, hiệu quả và tuân thủ kỷ luật đã thấm nhuần trong mọi hành động của Cán bộ nhân viên (CBNV), tạo nên sức mạnh tổng hợp đưa Vingroup phát triển vượt bậc trong mọi lĩnh vực tham gia.</p>
    <p>Phát huy 6 giá trị cốt lõi, Tập đoàn đã phát động các chương trình thi đua như phong trào &quot;Người tốt việc tốt&quot;, phong trào thi đua thực hành tiết kiệm hiệu quả, chiến dịch đào tạo 12 giờ chuyển đổi để thành công... Các chương trình giúp cho CBNV thay đổi cách nghĩ, cách làm việc, tiết kiệm thời gian và nâng cao hiệu quả công việc.</p>
    <p>Tại Vingroup, mỗi thành viên đều xác định và coi nơi đây là ngôi nhà thứ 2, nơi mình gắn bó và dành phần lớn thời gian hàng ngày để sống và làm việc. Ở bất cứ vai trò và vị trí nào, chúng tôi luôn tự hào là Người Vingroup.</p>
  </div>;
}

export default Culture;
