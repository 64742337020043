import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import {ReactComponent as IconSearch} from "assets/icons/search.svg";
import {ReactComponent as IconBack} from "assets/icons/arrow-left.svg";
import classNames from "classnames";
import Header from "../Header";
import Footer from "../Footer";

function BasicLayout() {
  const containerRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const handleScroll = () => {
    setOffsetTop(containerRef.current.scrollTop || 0);
  };

  const handleShowSearchModal = (event) => {
    if(event.target.classList.contains('search-modal__content') || event.target.classList.contains('search-modal__inp') || event.target.classList.contains('search-modal__icon') || event.target.classList.contains('search-modal__button') || event.target.classList.contains('input')) return;
    setShowSearchModal(!showSearchModal);
  };

  return <>
    <Header />
    <div className="container" onScroll={handleScroll} ref={containerRef}>
      <Outlet />
      <Footer />
      <button type="button" className={classNames("btn-search", offsetTop > 0 && "is-scroll")} aria-label="Search" onClick={handleShowSearchModal}><IconSearch className="icon" /></button>
    </div>
    <div className={classNames("search-modal", showSearchModal && "is-show")} onClick={handleShowSearchModal}>
      <div className="search-modal__content">
        <button type="button" className="search-modal__back" aria-label="Back"><IconBack className="icon" /></button>
        <form className="search-modal__inp">
          <button type="submit" aria-label="Search" className="search-modal__button"><IconSearch className="search-modal__icon icon" /></button>
          <input type="search" placeholder="Tìm kiếm trên trang web này" className="input" />
        </form>
      </div>
    </div>
  </>;
};

export default BasicLayout;
