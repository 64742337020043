import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Routers } from "routers";

const basename = document.querySelector("base")?.getAttribute("href") ?? "/";

function Root() {
  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
}

export default Root;
