import React from "react";
import "react-notifications/lib/notifications.css";
import "swiper/swiper-bundle.css";
import "./assets/scss/style.scss";
import Root from "./Root";

function App() {
  return <Root />;
}

export default App;
