import React from "react";
import banner from "assets/img/banner/banner06.webp";

function QuyThienTam() {
  return <>
    <div className="banner">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
    <div className="post-page">
      <h2 className="title title--italic">Quỹ thiện tâm</h2>
      <p>Quỹ Thiện Tâm là một tổ chức phi lợi nhuận thuộc Tập đoàn Vingroup, hoạt động vì mục đích nhân đạo, từ thiện, nhằm “chuyển tải một cách nhanh chóng và hiệu quả nhất tấm lòng của người Vingroup đến với cộng đồng”.</p>
      <p>Ra đời từ năm 2006, với toàn bộ chi phí hoạt động được tài trợ bởi Tập đoàn Vingroup và các nhà hảo tâm là lãnh đạo Tập đoàn Vingroup, đến nay Quỹ Thiện Tâm đã triển khai nhiều dự án, chương trình hành động thiết thực vì sự phát triển của cộng đồng, trong đó ưu tiên giúp đỡ các gia đình có hoàn cảnh khó khăn, gia đình có công với cách mạng như: Phụng dưỡng Mẹ Việt Nam anh hùng; Chăm lo các gia đình chính sách, gia đình có hoàn cảnh đặc biệt khó khăn; Giúp đỡ học sinh nghèo hiếu học; Hỗ trợ phát triển kinh tế cho các địa phương nghèo; Ủng hộ, cứu trợ đồng bào bị thiên tai; Xây dựng, phát triển các công trình y tế, văn hóa, giáo dục mang tính từ thiện và có ý nghĩa nhân văn cao.</p>
      <p>Đặc biệt, tháng 07/2010, Quỹ Thiện Tâm đã phối hợp cùng Giáo hội Phật giáo Việt Nam khánh thành Trung tâm dưỡng lão, hướng nghiệp và phát triển tài năng trẻ Phật Tích, nằm trong quần thể văn hóa Phật giáo Phật Tích (Bắc Ninh), là nơi nuôi dưỡng người già cô đơn, không nơi nương tựa và trẻ mồ côi, đối tượng chính sách trên cả nước, đem lại hiệu quả ý nghĩa lâu dài, góp phần chia sẻ một phần khó khăn của Nhà nước trong công tác xã hội và nuôi dưỡng, giáo dục, đào tạo các cháu trở thành người có ích cho cộng đồng.</p>
      <p>Với nhiều hoạt động lớn có ý nghĩa xã hội và nhân văn trên cả nước, Quỹ Thiện Tâm đã và đang nỗ lực hết sức để hoàn thành sứ mệnh của mình, trở thành một điển hình cho tinh thần tương thân tương ái của người Việt.</p>
    </div>
  </>;
}

export default QuyThienTam;
